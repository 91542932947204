<template>
  <div class="wrap">
    <!-- <el-button class="download" @click="toImage" type="primary">下载海报</el-button> -->

    <div class="poster-box" ref="pbox" id="p-box" @click="changePoster">
      <draggable
        :disabled="disabled"
        chosenClass="chosen"
        forceFallback="true"
        group="poster"
        animation="1000"
        @start="onStart"
        @end="onEnd"
      >
        <div
          class="item"
          v-for="(item, index) in imgArray"
          :key="index"
          :class="{ 'item-active': item.id == curEle.id }"
          :style="CssFormat(item.style)"
          @click.stop="changeEle(item, index)"
        >
          <!-- <div class="tl" v-if="item.id == curEle.id"></div>
                        <div class="tr" v-if="item.id == curEle.id"></div>
                        <div class="bl" v-if="item.id == curEle.id"></div>
                        <div class="br" v-if="item.id == curEle.id"></div> -->
        </div>

        <div
          class="item"
          v-for="(item, index) in TextArray"
          :key="index"
          :class="{ 'item-active': item.id == curEle.id }"
          @click.stop="changeEle(item, index)"
          :style="CssFormat(item.style)"
        >
          {{ item.value }}
        </div>
      </draggable>
    </div>

    <div class="l-tools">
      <el-form ref="ruleForm" label-width="60px" label-position="left">
        <el-form-item label="海报">
          <el-button @click="toImage" type="warning">保存海报</el-button>
        </el-form-item>
        <el-form-item label="文本">
          <el-button @click="addTextElement" type="primary">添加文本</el-button>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            :action="domain"
            :show-file-list="false"
            :on-success="UploadFaceImg"
            :data="QiNiYunL"
            :before-upload="gettoken"
          >
            <el-button type="primary">添加图片</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="模板">
          <!-- <el-button @click="addTextElement" type="primary">添加文本</el-button> -->
        </el-form-item>
      </el-form>
      <div class="temp-item flex-col jc-c al-c" v-for="(t,i) in tempList" :key="i">
        <img :src="t.pic" alt="" class="temp-img">
        <p class="temp-p">{{t.name}}</p>
      </div>
    </div>

    <div class="tools" v-if="textdrawer">
      <el-form ref="ruleForm" label-width="100px" label-position="left">
        <el-form-item label="文本">
          <el-button @click="addTextElement" type="primary" size="small"
            >添加文本</el-button
          >
          <el-button
            @click="delElement"
            type="danger"
            v-if="curIndex >= 0"
            size="small"
            >删除</el-button
          >
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="curEle.value"></el-input>
        </el-form-item>
        <el-form-item label="宽度">
          <el-input v-model="curStyle.width"></el-input>
        </el-form-item>
        <el-form-item label="高度">
          <el-input v-model="curStyle.height"></el-input>
        </el-form-item>
        <el-form-item label="左偏移量">
          <el-input v-model="curStyle.left"></el-input>
        </el-form-item>
        <el-form-item label="上偏移量">
          <el-input v-model="curStyle.top" type="number"></el-input>
        </el-form-item>
        <el-form-item label="行高">
          <el-input v-model="curStyle.lineHeight" type="number"></el-input>
        </el-form-item>
        <el-form-item label="颜色">
          <el-color-picker v-model="curStyle.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="字号">
          <el-input v-model="curStyle.fontSize"></el-input>
        </el-form-item>
        <el-form-item label="字体粗细">
          <el-select v-model="curStyle.fontWeight" placeholder="字体粗细">
            <el-option label="默认" value="400"> </el-option>
            <el-option label="加粗" value="600"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层级">
          <el-input v-model="curStyle.zIndex" type="number"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="tools" v-if="imgdrawer">
      <el-form ref="ruleForm" label-width="100px" label-position="left">
        <el-form-item label="图片">
          <el-upload
            :action="domain"
            :show-file-list="false"
            :on-success="UploadFaceImg"
            :data="QiNiYunL"
            :before-upload="gettoken"
          >
            <el-button size="small" type="primary">添加图片</el-button>
          </el-upload>
          <el-button
            @click="delElement"
            type="danger"
            size="small"
            v-if="curIndex >= 0"
            >删除</el-button
          >
        </el-form-item>
        <el-form-item label="图片路径">
          <el-input v-model="curEle.url"></el-input>
        </el-form-item>
        <el-form-item label="宽度">
          <el-input v-model="curStyle.width"></el-input>
        </el-form-item>
        <el-form-item label="高度">
          <el-input v-model="curStyle.height"></el-input>
        </el-form-item>
        <el-form-item label="左偏移量">
          <el-input v-model="curStyle.left"></el-input>
        </el-form-item>
        <el-form-item label="上偏移量">
          <el-input v-model="curStyle.top" type="number"></el-input>
        </el-form-item>
        <el-form-item label="层级">
          <el-input v-model="curStyle.zIndex" type="number"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {temp} from './temp.js'
import html2canvas from "html2canvas";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      temp: {
        width: 524,
        height: 734,
      },
      tempList:[],
      disabled: false,
      textdrawer: false,
      imgdrawer: false,
      scale: false,
      num: 100,
      curEle: {},
      curType: "",
      curIndex: -1,
      curStyle: {},
      TextArray: [],
      imgArray: [],
      rectArray: [],
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      down: false,
      domain: "http://up-z0.qiniup.com/",
      upload_qiniu_addr: "https://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
    };
  },

  mounted() {
    this.tempList = temp;
  },

  methods: {
    changePoster() {
      this.curType = "poster";
      this.curIndex = -1;
      this.curEle = {};
    },
    //css处理，添加px单位
    CssFormat(item) {
      return {
        width: item.width + "px",
        height: item.height + "px",
        color: item.color,
        zIndex: item.zIndex,
        top: item.top + "px",
        left: item.left + "px",
        textAlign: item.textAlign,
        fontSize: item.fontSize + "px",
        fontWeight: item.fontWeight,
        lineHeight: item.lineHeight,
        backgroundImage: item.backgroundImage,
        backgroundColor:item.backgroundColor,
      };
    },
    //选择元素
    changeEle(item, index, e) {
      this.curType = item.type;
      this.curIndex = index;
      if (item.type == "text") {
        this.curEle = this.TextArray[index];
        this.curStyle = this.TextArray[index].style;
        this.textdrawer = true;
        this.imgdrawer = false;
      } else if (item.type == "img") {
        this.curEle = this.imgArray[index];
        this.curStyle = this.imgArray[index].style;

        this.textdrawer = false;
        this.imgdrawer = true;
      }

      console.log(e);
    },
    delElement() {
      if (this.curType == "text") {
        this.TextArray.splice(this.curIndex, 1);
      } else if (this.curType == "img") {
        this.imgArray.splice(this.curIndex, 1);
      }
      this.textdrawer = false;
      this.imgdrawer = false;
    },
    addTextElement() {
      this.num++;
      let ele = {
        id: this.num,
        value: "新增文本",
        type: "text",
        style: {
          width: 300,
          height: 30,
          color: "#000000",
          zIndex: 2,
          top: 30,
          left: 0,
          textAlign: 1,
          fontSize: 24,
          fontWeight: 400,
          lineHeight: 1,
        },
      };
      this.TextArray.push(ele);
    },
    mousedown(e) {
      if (this.curType == "img") {
        this.startX = e.offsetX;
        this.startY = e.offsetY;
        let width = e.target.clientWidth;
        let height = e.target.clientHeight;
        let y = e.target.clientHeight - e.offsetY;
        let x = e.target.clientWidth - e.offsetX;
        if (x >= width - 8 && y >= height - 8) {
          this.disabled = true;
          this.scale = true;
          this.down = true;
        }
        if (x >= width - 8 && y <= 8) {
          this.disabled = true;
          this.scale = true;
          this.down = true;
        }
        if (x <= 8 && y >= height - 8) {
          this.disabled = true;
          this.scale = true;
          this.down = true;
        }
        if (x <= 8 && y <= 8) {
          this.disabled = true;
          this.scale = true;
          this.down = true;
        }
      }
    },
    mousemove(e) {
      if (this.curType == "img" && this.down) {
        console.log(this.startX, e.offsetX);
        let sc = e.offsetX - this.startX;
        // if(this.startX < e.offsetX){
        //     sc = (e.offsetX - this.startX + e.target.clientWidth)/e.target.clientWidth
        // }else{
        //     sc = (e.target.clientWidth - (this.startX - e.offsetX))/e.target.clientWidth
        // }
        console.log(sc);
        console.log(e.target.clientWidth * sc);
        this.imgArray[this.curIndex].style.width =
          Number(this.imgArray[this.curIndex].style.width) + sc;
        this.imgArray[this.curIndex].style.height =
          Number(this.imgArray[this.curIndex].style.height) + sc;
      }
    },
    mouseup(e) {
      this.disabled = false;
      this.scale = false;
      this.down = false;
      console.log(e);
    },
    onStart(e) {
      this.startX = e.originalEvent.offsetX;
      this.startY = e.originalEvent.offsetY;
    },
    onEnd(e) {
      this.disabled = false;
      this.scale = false;
      this.down = false;
      this.endX = e.originalEvent.offsetX;
      this.endY = e.originalEvent.offsetY;
      let x = this.endX - this.startX;
      let y = this.endY - this.startY;
      if (x < 0) {
        x = 0;
      } else if (x > this.temp.width) {
        x = this.temp.width - 20;
      }
      if (y < 0) {
        y = 0;
      } else if (y > this.temp.height) {
        y = this.temp.height - 20;
      }

      if (this.curType == "text") {
        this.TextArray[this.curIndex].style.left = x - 1;
        this.TextArray[this.curIndex].style.top = y - 1;
      } else if (this.curType == "img") {
        this.imgArray[this.curIndex].style.left = x - 1;
        this.imgArray[this.curIndex].style.top = y - 1;
      }
    },
    UploadFaceImg(file) {
      let img = this.upload_qiniu_addr + file.key;
      this.num++;
      let ele = {
        id: this.num,
        type: "img",
        style: {
          width: "300",
          height: "300",
          zIndex: "",
          top: 0,
          left: 0,
          backgroundImage: `url(${img})`,
        },
      };
      this.imgArray.push(ele);
    },
    async before() {
      let res = await this.gettoken();
      return res;
    },
    gettoken() {
      return new Promise((resolve, reject) => {
        let _this = this;
        var timestamp = new Date().getTime();
        let data = {
          key: timestamp + ".png",
          type: "pic",
        };
        _this
          .$axios({
            method: "post",
            url: "https://www.curiousmore.com/qmore/push/upload/getToken",
            data: data,
          })
          .then((res) => {
            _this.QiNiYunL.token = res.data.data;
            _this.QiNiYunL.key = data.key;
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      });
    },
    //下载海报
    toImage() {
      window.scrollTo(0, 0);
      // 手动创建一个 canvas 标签
      const canvas = document.createElement("canvas");
      // 获取父标签，意思是这个标签内的 DOM 元素生成图片
      // imageTofile是给截图范围内的父级元素自定义的ref名称
      let canvasBox = this.$refs.pbox;
      // 获取父级的宽高
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";
      const context = canvas.getContext("2d");
      context.scale(2, 2);
      const options = {
        canvas: canvas,
        useCORS: true, //
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        let dataURL = canvas.toDataURL("image/png");
        // 下面是创建a标签下载操作
        let a = document.createElement("a");
        a.href = dataURL;
        a.download = "海报";
        a.click();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  position: relative;
  height: 100%;
}
.poster-box {
  width: 524px;
  height: 734px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
}
.imgitem {
  position: absolute;
}
.item {
  position: absolute;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-size: 100% 100%;
}
.item-active {
  border: 1px dashed #409eee;
  cursor: move;
}

.temp-item{
    margin-bottom:10px ;
}
.temp-img{
    width: 150px;
    height: 200px;
}
.temp-p{
    text-align: center;
}
.l-tools {
  top: -20px;
  width: 250px;
  height: 100vh;
  position: absolute;
  left: 0;
  background-color: #fff;
  padding: 20px 10px;
}
.tools {
  top: -20px;
  width: 300px;
  height: 100vh;
  position: absolute;
  right: 0;
  background-color: #fff;
  padding: 20px 10px;
}
.tl {
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #409eee;
  cursor: se-resize;
}
.tr {
  width: 8px;
  height: 8px;
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #409eee;
  cursor: ne-resize;
}
.bl {
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  border: 1px solid #409eee;
  cursor: ne-resize;
}
.br {
  width: 8px;
  height: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid #409eee;
  cursor: se-resize;
}
</style>