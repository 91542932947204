const temp = [{
    id:1,
    name:'海报',
    pic:'https://img.curiousmore.com/1666185510966.png',
    temp:{
        width: 524,
        height: 734,
    },
    TextArray:[],
    imgArray:[]
}]

export {temp}